/* Start:: ecommerce */
.pro-img-box {
    position: relative;
}
.product-sale {
    position: absolute;
    width: 100%;
    inset-block-start: 10px;
    inset-inline-start: 13px;
    inset-inline-end: 5px;
    .badge {
        color: #fff;
        font-size: 12px !important;
        margin-block-start: 10px;
        padding: 3px 5px 4px;
    }
    .wishlist {
        float: var(--float-right);
        inset-inline-end: 26px;
        position: absolute;
        font-size: 18px !important;
        inset-block-start: 5px;
        color: $primary;
    }
}
.card-item-desc .card-item-desc-1 dt {
    font-weight: 500;
    font-size: 13px;
    margin-block-end: 0;
}

.adtocart {
    background: linear-gradient(45deg, #f93a5a, #f7778c) !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    display: inline-block;
    text-align: center;
    border: 3px solid #fff;
    inset-inline-start: 44%;
    bottom: -24px;
    position: absolute;
    i {
        color: #fff;
        font-size: 30px;
        line-height: 44px;
    }
}
.prev-price {
    text-decoration: line-through;
}
.product-title, .price, .sizes, .colors {
    text-transform: uppercase;
    font-weight: bold;
}
.stars {
    .checked {
        color: $warning;
    }
}
.details {
    .size {
        margin-inline-end: 10px;
        &:first-of-type {
            margin-inline-start: 40px;
        }
    }
}
.qunatity-list {
    .choices__inner {
        width: 90px;
    }
}

.colorinput {
    margin: 0;
    position: relative;
    cursor: pointer;
}
.colorinput-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.colorinput-color {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 3px;
    border: 1px solid $default-border;
    color: $white;
  
    &:before {
      content: "";
      opacity: 0;
      position: absolute;
      inset-block-start: 0.25rem;
      inset-inline-start: 0.25rem;
      height: 1.25rem;
      width: 1.25rem;
      transition: 0.3s opacity;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
    }
  }

  .colorinput-input:checked~.colorinput-color:before {
    opacity: 1;
  }

  .product-title, .rating, .product-description, .price, .vote, .sizes {
    margin-block-end: 15px;
}

// @media (min-width: 991px) and (max-width: 1999px) {
    .related-products-ltr-l .product-des {
        display: flex;
        justify-content: space-between;
        padding: 15px 0px;
    }
    .related-products-ltr-l .product-des .cardprice {
        position: unset;
    }
// }
.item-card {
    .relative {
        position: relative;
    }
    .cardtitle {
        span {
        display: block;
        font-size: 0.75rem;
        }
    }
    a {
        font-weight: 500;
        text-decoration: none;
    }
    .cardprice span {
        display: block;
        &.type--strikethrough {
            opacity: 0.7;
            text-decoration: line-through;
        }
    }
    .type--strikethrough {
        text-decoration: line-through;
        color: $text-muted;
    }
}

.feature2 i {
    line-height: 3rem;
    font-size: 20px;
    margin-block-end: 15px;
}

.product-details {
    .media img {
        width: 60px;
        height: 60px;
        margin-inline-end: 20px;
        border-radius: 5px;
        max-width: inherit;
        border: 1px solid $default-border;
    }
}

.product-quantity-container {
    width: 8.75rem;
    .input-group>.form-control {
        min-width: 3.125rem;
    }
}
.card-item-desc .card-item-desc-1 dt, dd {
    display: inline-block;
}
.card-item-desc dl {
    margin-block-end: 0;
}
.cardprice-2 {
    text-align: end;
}



/* Start:: Checkout */
.product-checkout {
    .tab-style-2 .nav-item .nav-link {
      padding: 0.85rem 2rem;
    }
    .tab-style-2 .nav-item .nav-link.active:before {
        inset-inline-start: 24%;
        width: 62%;
    }
    .tab-style-2.nav-tabs .nav-link.active {
        background-color: transparent;
    }
    .form-floating {
      input,
      textarea {
        font-size: 0.813rem;
        font-weight: 600;
      }
    }
    .shipping-method-container,
    .payment-card-container {
      position: relative;
      padding: 0.625rem;
      border: 1px solid $input-border;
      border-radius: $default-radius;
      .form-check-input {
        position: absolute;
        inset-inline-end: 0.75rem;
        inset-block-start: 1.2rem;
      }
      @media (min-width: 576px) {
        .shipping-partner-details,
        .saved-card-details {
          width: 12.5rem;
        }
      }
    }
  }
  .checkout-payment-success {
    text-align: center;
    img {
      width: 200px;
      height: 200px;
    }
  }

#shipped-tab-pane {
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block-end: 30px;
        border-block-end: 1px solid $default-border;
        margin-block-end: 30px;
        .left {
            display: flex;
            align-items: center;
        }
        .thumb {
            display: inline-flex;
            width: 100px;
            height: 90px;
            justify-content: center;
            align-items: center;
            border: 0 solid $default-border;
        }
        .purchase {
            display: inline-block;
            margin-inline-start: 21px;
            color: $teal;
        }
    }
}
.handle-counter {
    input {
        float: left;
        text-align: center;
        border-radius: 0;
        width: 0!important;
        border: 1px solid $default-border;
        outline: 0;
        border-inline-start: 0;
        border-inline-end: 0;
    }
}


.card-pay .tabs-menu li {
    width: 33.3%;
    display: block;
  }
  .card-pay .tabs-menu li a.active {
    background:  $primary;
    color: $white;
  }
  .card-pay .tabs-menu li a {
    padding: 0.7rem 1rem;
    display: block;
    text-align: center;
  }
  .card-pay .tabs-menu.nav {
    background: $light;
  }
  .card-pay .tabs-menu {
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  @media (max-width:1350px) {
    .tabs-menu {
      display: block;
    }
    .card-pay .tabs-menu li {
      width: 100% !important;
    }
  }
  /* End:: Checkout */
  [data-width="boxed"] {
    @media (min-width: 1400px) {
        .wishlist-row .col-xxl-3.wishlist-card {
            width: 33%;
        }
    }
}
@media (min-width: 1400px) and (max-width: 1600px) {
    .wishlist-row .col-xxl-3.wishlist-card {
        width: 33%;
    }
}

.card-item-desc .card-item-desc-1 {
    dt {
        font-weight: 500;
        font-size: 13px;
    }
    dd {
        font-size: 13px;
        color: $gray-6 ;
        margin-block-end: 0;
    }
}
/* End:: ecommerce */