/* Start:: vertical */
.main-sidebar {
    margin-block-start: 4rem;
    padding-block-start: 0.5rem;
    padding-block-end: 5rem;
    height: 100vh;
    position: relative;
    overflow: auto;
}
[dir="rtl"] {
    .app-sidebar {
        .fe-chevron-right {
            &::before {
                content: "\e92e";
            }
        }
    }
    .sidemenu-toggle .header-link {
        .header-link-icon {
            transform: rotate(180deg);
        }
    }
}
.app-sidebar {
    width: 15rem;
    height: 100%;
    background: $menu-bg;
    border-inline-end: 1px solid $menu-border-color;
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 103;
    transition: all .05s ease;
    .main-sidebar-header {
        width: 15rem;
        height: 4rem;
        position: fixed;
        display: flex;
        background: $menu-bg;
        z-index: 9;
        align-items: center;
        justify-content: center;
        padding: 0.9375rem 1.25rem;
        border-inline-end: 1px solid $menu-border-color;
        border-block-end: 1px solid $menu-border-color;
        transition: all .05s ease;
        .header-logo {
            img {
                height: 2rem;
                line-height: 2rem;
            }
            .desktop-logo {
                display: block;
            }
            .desktop-dark,
            .toggle-logo,.desktop-white,.toggle-white {
                display: none;
            }
        }
    }
    .slide__category {
        color: $menu-category-color;
        font-size: 0.6875rem;
        font-weight: 700;
        letter-spacing: 0.03125rem;
        text-transform: uppercase;
        white-space: nowrap;
        position: relative;
        margin-block-end: 12px;
        margin-block-start: 25px;
        height: 15px;
        padding-inline-start: 25px;
        padding-inline-end: 20px;
    }
    .slide {
        margin: 0;
    }
    .side-menu__item {
        font-size: 13px;
        padding-inline-start: 22px;
        padding-inline-end: 20px;
        padding-block: 10px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $menu-prime-color;
        &.active,
        &:hover {
            color: $primary;
            .side-menu__label,.side-menu__angle {
                color: $primary;
            }
            .side-menu__icon {
                fill: $primary;
                color: $primary;
            }
        }
        &.active::before {
            content: "";
            width: 3px;
            height: 40px;
            background: $primary;
            position: absolute;
            inset-inline-start: 0;
          }
    }
    .slide-menu {
        &.child1,&.child2,&.child3 {
            .side-menu__item.active {
                background-color: transparent;
            }
        }
    }
    .slide.has-sub.open {
        .side-menu__icon {
            fill: $primary;
        }
        .side-menu__label {
            color: $primary;
        }
    }
    .slide-menu {
        padding: 0rem;
    }
    .slide-menu {
        &.child1,
        &.child2 {
            .side-menu__item {
                color: $menu-secondary-color;
                padding-block: 8px;
                padding-inline-start: 28px;
                padding-inline-end: 20px;
            }
        }
    }
    .slide-menu {
        &.child1,
        &.child2,
        &.child3 {
            .side-menu__item {
                &:before {
                    content: "\e92f";
                    font-family: "feather" !important;
                    position: absolute;
                    inset-inline-start: 0px;
                    font-size: 9px;
                    color: $menu-secondary-color;
                }
                &:hover {
                    color: $primary;
                    &:before {
                        color: $primary;
                    }
                }
                &.active {
                    &:before {
                        height: inherit;
                        width: inherit;
                        background: transparent;
                        color: $primary;
                    }
                    color: $primary;
                    font-weight: 400;
                    &:hover {
                        color: $primary;
                    }
                }
            }
            li {
                padding: 0;
                position: relative;
            }
        }
    }
    .side-menu__label {
        white-space: nowrap;
        color: $menu-prime-color;
        position: relative;
        font-size: 0.85rem;
        line-height: 1;
        vertical-align: middle;
    }
    .side-menu__icon {
        margin-inline-end: 0.875rem;
        width: 1.375rem;
        height: 1.375rem;
        font-size: 1.4375rem;
        text-align: center;
        line-height: 34px;
        color: $menu-prime-color;
        fill: $menu-prime-color;
        border-radius: $default-radius;
    }
    .side-menu__angle {
        transform-origin: center;
        position: absolute;
        inset-inline-end: 1.2rem;
        font-size: 0.85rem;
        color: $menu-prime-color;
        transition: all .05s ease;
    }
    .slide.side-menu__label1 {
        display: none;
    }
    .simplebar-content {
        padding-block-start: 0 !important;
    }
}
.horizontal-logo {
    padding: 0.85rem 0rem;
}
.slide.has-sub .slide-menu {
    transform: translate(0, 0) !important;
    visibility: visible !important;
    padding-inline-start: 29px;
}
.nav ul li {
    list-style-type: none;
}
.nav > ul {
    padding-inline-start: 0px;
}
.slide-menu {
    display: none;
}
.slide.has-sub {
    display: grid;
    &.open {
        > .side-menu__item .side-menu__angle {
            transform: rotate(90deg);
            color: $primary;
        }
    }
}
@media (min-width:992px) {
    [data-toggled="open"] {
        .page {
            position: absolute;
        }
    }
}

/* Responsive Styles Start */

@media (max-width: 991.98px) {
    .horizontal-logo {
        .header-logo {
            .desktop-logo,.desktop-dark,.desktop-white,.toggle-white {
                display: none;
            }
            .toggle-logo {
                display: block;
            }
        }
    }
    .main-content {
        padding-block-start: 3.75rem;
    }
    .main-sidebar-header {
        display: none !important;
    }
    .main-sidebar {
        margin-block-start: 0;
    }
    .app-sidebar {
        inset-block-start: 0;
    }
}

.slide-left,
.slide-right {
    display: none;
}
[data-nav-layout = "vertical"] {
    .slide.has-sub {
        &.active {
            > .side-menu__item {
                margin-block: 0.25rem;
            }
        }
    }
}
@media (min-width:992px) {
    .horizontal-logo {
        display: none;
    }
}
/* Responsive Styles End */

/* Responsive Overlay Start */
#responsive-overlay {
    visibility: hidden;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 101;
    background-color: rgba(15, 23, 42, 0.5);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
    &.active {
        visibility: visible;
    }
}
/* Responsive Overlay End */
@media (min-width: 992px) {
[data-toggled=icon-click-closed],
[data-toggled=icon-hover-closed],
[data-toggled=icon-text-close], 
[data-toggled=menu-click-closed],
[data-toggled=menu-hover-closed] {
        .app-content {
            min-height: calc(100vh + 50rem);
        }
    } 
}

.app-sidebar .slide-menu.child2 .side-menu__item {
    padding-inline-start: 19px;
    font-size: 12px;
    padding-block: 7px;
}
[dir="rtl"] {
    .app-sidebar .slide-menu {
        &.child1, &.child2, &.child3 {
            .side-menu__item:before {
                content: "\e92e";
            }
        }
    }
}

@media (max-width: 991.98px) {
    .main-menu {
        margin: 0!important;
    }
}
/* End:: vertical */