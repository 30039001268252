/*Start:: dashboard_styles*/

/* Start:: Index Breadcrumb*/
.main-content-title {
    font-weight: 500;
    font-size: 24px;
    text-indent: -1px;
    line-height: 1;
    position: relative;
    margin-block-end: 20px;
  }
  /* End:: Index Breadcrumb */

/*Start:: ORDER STATUS*/
.total-revenue {
    display: flex;
    padding: 0 10px 20px;
    flex: none;
    position: absolute;
    z-index: 0;
    margin-block-end: .5rem;
    h4 {
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.5px;
        margin-block-end: 3px;
        font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    >div+div {
        margin-inline-start: 20px;
        padding-inline-start: 20px;
    }
    label {
        font-size: 0.875rem;
        span {
            display: inline-block;
            width: 9px;
            height: 9px;
            margin-inline-end: 5px;
            border-radius: 100%;
        }
    }
}
/*End:: ORDER STATUS*/

/*Start:: Sales Bar Chart*/
.sales-bar {
    min-height: 180px !important;
    position: relative;
    inset-block-end: -18px;
    .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series .apexcharts-bar-area {
        stroke: transparent !important;
    }
}
/*End::Sales Bar Chart*/

/*Start:: Dashboard-map*/
.card-dashboard-map-one {
    #us-map1 {
       height: 18rem;
       g{
            >path:nth-child(23) {
                fill: rgba(249,58,90,1) !important;
            }
            >path:nth-child(29) {
                fill: $primary-04 !important;
            }
            >path:nth-child(8) {
                fill: rgba(249,58,90,1) !important;
            }
            >path:nth-child(6) {
                fill: rgba(247,165,86,1) !important;
            }
            >path:nth-child(40) {
                fill: rgba(247,165,86,1) !important;
            }
            >path:nth-child(42) {
                fill: $primary-05 !important;
            }
            >path:nth-child(19) {
                fill: $primary-06 !important;
            }
       }
    }
}
/*End:: Dashboard-map*/

/*Start:: Charts*/
#spark1, #spark2, #spark3, #spark4, #spark5 {
    .apexcharts-grid line {
        stroke: transparent;
    }
}
/*End:: Charts*/
/*Start:: Product Timeline*/
.product-timeline {
    ul.timeline-1 {
        list-style-type: none;
        position: relative;
        &:before {
            content: " ";
            margin: 20px 20px 0 22px;
            display: inline-block;
            position: absolute;
            inset-inline-start: -2px;
            height: 94%;
            z-index: 0;
            content: "";
            position: absolute;
            bottom: 0;
            border-inline-start: 2px dotted $default-border;
        }
        >li {
            margin: 21.75px 0;
            padding-inline-start: 1rem;
            .product-icon {
                width: 37px;
                height: 37px;
                text-align: center;
                border-radius: 50%;
                position: absolute;
                inset-inline-start: 3px;
                font-size: 20px;
                line-height: 37px;
                font-size: 17px;
            }
        }
    }
}
/*End:: Product Timeline*/

/*Start:: Recent Order*/
.legend {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 0;
    margin-inline-end: 10px;
    margin-block-start: 6px;
}

.sales-info h3 {
    font-size: 21px;
    font-weight: 700;
}
/*End:: Recent Order*/

/*Start:: Progress bar*/
.progress-style {
    &.progress {
        overflow: visible !important;
        .progress-bar:after {
            content: "";
            width: 15px;
            height: 15px;
            border-radius: 50%;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            background: #fff;
            position: absolute;
            inset-inline-end: -5px;
            top: -5px;
        }
        .progress-bar {
            position: relative;
            overflow: visible !important;
        }
    }
    &.progress-sm {
       height: 4px;
    }
    
    .bg-primary-gradient.progress-bar:after, .progress-style .bg-primary.progress-bar:after {
        border: 4px solid $primary;
    }
    .bg-danger-gradient.progress-bar:after {
        border: 4px solid $danger;
    }
}
/*End:: Progress bar*/
/*Start:: Stroked Circular Gauge chart*/
#chart div:first-child {
    margin-inline-end: auto;
    margin-inline-start: auto;
}
/*End:: Stroked Circular Gauge chart*/

/*Start:: top countries card*/
.top-countries-card {
    border-color: $default-border;
    padding: 20px;
    .list-group-item {
        padding: 10px 0;
        border-left-width: 0;
        border-right-width: 0;
        border-style: solid;
        border-color: $default-border;
        background-color: transparent !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        span {
            display: block;
            margin-inline-start: auto;
            margin-inline-end: 10px;
            font-weight: 600;
            font-family: "Archivo", Arial, sans-serif;
        }
        p {
            margin-block-end: 0;
            margin-inline-start: 10px;
        }
    }
}
/*End:: top countries card*/

/*start:: table card*/
.card-table {
    padding: 20px;
}

.country-table {
    .table {
        th, td {
            padding: 10px 15px;
            line-height: 1.35;
        }
       &.table-striped>tbody>tr:nth-of-type(odd)>* {
            color: $default-text-color !important;
        }
    }
}

.card-table-two, .top-countries-card {
    .card-title {
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        margin-block-end: 10px;
        letter-spacing: 0.2px;
    }
}
/*End:: table card*/
.main-dashboard-header-right {
    > div {
  
      &:first-child {
        flex-basis: 100%;
      }
  
      + div {
        margin-block-start: 10px;
      }
    }
  }
  
  @media (min-width: 576px) {
    .page-header-breadcrumb  .main-dashboard-header-right {
      flex-wrap: nowrap;
    }
  }
  
  @media (min-width: 992px) {
    .page-header-breadcrumb  .main-dashboard-header-right {
      margin-block-start: 0;
      > div {
        text-align: end;
        + div {
            margin-inline-start: 50px;
          }
      }
    }
  }
  
  @media (max-width: 575.98px) {
    .page-header-breadcrumb .main-dashboard-header-right > div + div {
      flex-basis: 50%;
    }
    .total-revenue {
        h4 {
            font-size: 17px !important;
        }
        label {
            font-size: 0.775rem !important;
        }
        >div+div {
            margin-inline-start: 20px !important;
            padding-inline-start: 3px !important;
        }
    }
  }
  
  @media (min-width: 576px) {
    .page-header-breadcrumb .main-dashboard-header-right > div + div {
      margin-inline-start: 25px;
      margin-block-start: 0;
      flex-shrink: 0;
    }
  }

  .main-dashboard-header-right {
    margin-block-start: 20px;
    display: flex;
    flex-wrap: wrap;
}

/* start:: card dashboard-map*/
.card-dashboard-map-one {
    border-color: $default-border;
    padding: 20px;
}
/* End:: card dashboard-map*/

/*End:: dashboard_styles*/