/* Start:: avatars */
.avatar {
    position: relative;
    height: 2.625rem;
    width: 2.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: $default-radius;
    color: $white;
    font-weight: 500;
    a.badge:hover {
        color: $white;
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: $default-radius;
    }
    &.avatar-rounded {
        border-radius: 50%;
        img {
            border-radius: 50%;
        }
    }
    &.avatar-radius-0 {
        border-radius: 0;
        img {
            border-radius: 0;
        }
    }
    .avatar-badge {
        position: absolute;
        inset-block-start: -4%;
        inset-inline-end: -0.375rem;
        width: 1.4rem;
        height: 1.4rem;
        font-size: 0.625rem;
        border: 2px solid #ffffff;
        border-radius: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.online,&.offline {
        &:before {
            position: absolute;
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            inset-inline-end: 0;
            inset-block-end: 0;
            border: 2px solid $custom-white;
        }
    }
    &.online:before {
        background-color: rgb(var(--success-rgb));
    }
    &.offline:before {
        background-color: $gray-5;
    }
    &.avatar-xs {
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem;
        font-size: 0.65rem;
        .avatar-badge {
            padding: 0.25rem;
            width:  1rem;
            height: 1rem;
            line-height: 1rem;
            font-size: 0.5rem;
            inset-block-start: -25%;
            inset-inline-end: -0.5rem;
        }
    }
    &.avatar-sm {
        width: 1.75rem;
        height: 1.75rem;
        line-height: 1.75rem;
        font-size: 0.65rem;
        .avatar-badge {
            padding: 0.3rem;
            width: 1.15rem;
            height: 1.15rem;
            line-height: 1.15rem;
            font-size: .5rem;
            inset-block-start: -36%;
            inset-inline-end: -0.5rem;
        }
        &.online,&.offline {
            &:before {
                width: 0.5rem;
                height: 0.5rem;
            }
        }    
    }
    &.avatar-md {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 0.8rem;
        .avatar-badge {
            padding: 0.4rem;
            width:  1.2rem;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.65rem;
            inset-block-start: -6%;
            inset-inline-end: -13%;
        }
        &.online,&.offline {
            &:before {
                width: 0.75rem;
                height: 0.75rem;
            }
        }    
        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    &.avatar-lg {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        font-size: 1rem;
        .avatar-badge {
            inset-block-start: -15%;
            inset-inline-end: -0.25%;
        }
        &.online,&.offline {
            &:before {
                width: 0.8rem;
                height: 0.8rem;
            }
        }    
        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
    &.avatar-xl {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        font-size: 1.25rem;
        .avatar-badge {
            inset-block-start: -8%;
            inset-inline-end: -0.2%;
        }
        &.online,&.offline {
            &:before {
                width: 0.95rem;
                height: 0.95rem;
            }
        }    
    }
    &.avatar-xxl {
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
        font-size: 1.5rem;
        .avatar-badge {
            inset-block-start: -4%;
            inset-inline-end: 0rem;
        }
        &.online,&.offline {
            &:before {
                width: 1.05rem;
                height: 1.05rem;
                inset-block-end: 0.25rem;
            }
        }    
    }
}
.avatar-list-stacked {
    padding: 0;
    .avatar {
        margin-inline-end: -0.65rem !important;
        border: 1px solid rgba(0,0,0,0.05);
        vertical-align: middle;
        transition: transform ease 200ms;
        &:last-child {
            margin-inline-end: 0 !important;
        }
        &:hover {
            z-index: 1;
            transform: translateY(-0.188rem);
        }
    }
}
[dir="rtl"] {
    .avatar-list-stacked {
        .ri-arrow-right-s-line {
            transform: rotate(180deg);
        }
    }
}
/* End:: avatars */