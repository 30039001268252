/* Start:: icontext */
[data-vertical-style="icontext"][data-toggled="icon-text-close"] {
    @media (min-width: 992px) {
        &:not([data-icon-text="open"]) {
            .app-sidebar {
                // position: absolute;
                width: 9rem;
                .main-sidebar {
                    overflow: visible;
                }
                .main-sidebar-header {
                    width: 9rem;
                }
                .side-menu__icon {
                    margin-inline-end: 0;
                    margin-block-end: 0.5rem;
                }
                .slide {
                    padding: 0;
                }
                .slide__category,
                .side-menu__angle {
                    display: none;
                }
                .slide.has-sub.open {
                    .slide-menu {
                        display: none !important;
                    }
                }    
                .side-menu__item {
                    border-radius: 0;
                }
                .side-menu__item,
                .side-menu__label {
                    display: block;
                    text-align: center;
                }
            }
            &[data-menu-position="fixed"] {
                .app-sidebar {
                    position: fixed;
                }
            } 
            .app-header {
                padding-inline-start: 9rem;
            }
        }
        .app-content {
            margin-inline-start: 9rem;
        }
        &[data-nav-layout=vertical] {
            .app-sidebar .main-sidebar-header .header-logo {
                .toggle-logo {
                    display: block;
                }
                .desktop-logo {
                    display: none;
                }
            }
            
            &[data-menu-styles="dark"],
            &[data-menu-styles="transparent"],
            &[data-menu-styles="color"],
            &[data-menu-styles="gradient"] {
                .app-sidebar .main-sidebar-header .header-logo {
                    .toggle-white {
                        display: block;
                    }
                    .toggle-logo, .desktop-logo, .desktop-white {
                        display: none;
                    }
                }
            }

            .menu-badge {
                display: none;
            }
        }
        &[data-icon-text="open"] {
            .app-sidebar {
                width: 15rem;
                .main-sidebar-header {
                    width: 15rem;
                    .header-logo {
                        .desktop-logo {
                            display: block;
                        }
                        .toggle-logo {
                            display: none;
                        }
                    }
                }
                .side-menu__icon { 
                    margin-block-end: 0;
                    margin-inline-end: 0.625rem;
                }
                .slide-menu {
                    &.child1,.child2,.child3 {
                        li {
                            padding: 0;
                        }
                    }
                }
            }
            &[data-menu-styles="dark"] {
                .app-sidebar .main-sidebar-header .header-logo {
                    .desktop-white {
                        display: block;
                    }
                    .toggle-logo, .desktop-logo, .toggle-white {
                        display: none;
                    }
                }
            }
        }
    }
}

/* End:: icontext */