/* Start:: header */
/* Start::app-header */
.app-header {
  max-width: 100%;
  z-index: 100;
  position: fixed;
  inset-block-start: 0;
  inset-inline: 0;
  background: $header-bg;
  border-block-end: 1px solid $header-border-color;
  transition: all .1s ease;

  #mainHeaderProfile {
    color: $header-prime-color;
  }
}

@media (min-width: 992px) {
  .app-header {
    padding-inline-start: 15rem;
  }
}

.header-icon {
  color: $header-prime-color;
}

.header-icon-badge {
  position: absolute;
  inset-block-start: 0.4rem;
  inset-inline-end: 0.45rem;
  font-size: .625rem;
  line-height: .9;
}

.related-app {
  text-align: center;
  border-radius: $default-radius;
  border: 1px solid $default-border;

  &:hover {
    background-color: $light;
  }
}

/* End::app-header */

/* Start::main-header-container */
.main-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .header-content-left,
  .header-content-right {
    display: flex;
    align-items: center;
  }

  .header-element {
    display: flex;
    align-items: center;
  }

  .header-link {
    display: flex;
    align-items: center;
    padding: .8rem 0.65rem;

    &:hover,
    &.show {
      .header-link-icon {
        fill: $header-prime-color;
        color: $header-prime-color;
      }
    }
   &:hover .header-link-icon {
      opacity: 0.8;
      fill: $default-text-color;
      color: $default-text-color;
    }
  }

  .header-link-icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.25rem;
    color: $header-prime-color;
    fill: $header-prime-color;
    transition: all ease 0.05s;
    position: relative;
    padding: 0.08rem;
    border-radius: 50%;
    &.full-screen-icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .dropdown-toggle {
    text-decoration: none;

    &::after {
      content: none;
    }
  }

  .main-profile-user {
    .dropdown-menu {
      width: 13rem;
    }

    .dropdown-item {
      font-weight: 400;
      font-size: 0.813rem;
      color: $default-text-color;
      padding: 1.25rem 1rem;
      height: 2.375rem;
      display: flex;
      align-items: center;

      &:hover {
        color: $primary;

        i {
          color: $primary;
          opacity: 1;
        }
      }
    }
  }

  .main-header-dropdown {
    box-shadow: 0 0.25rem 0.625rem rgba(20,20,20,0.1);
    inset-block-start: 5px !important;
    border-color: transparent;
    &.header-shortcuts-dropdown {
      width: 22rem;
    }
    .dropdown-item {
      padding: 0.65rem;
      border-block-end: 1px solid $default-border;
      &:last-child {
        border-block-end: 0;
      }
    }
    &.header-profile-dropdown {
      inset-block-start: -2px !important;
    }
    &.country-dropdown {
      inset-block-start: 7px !important;
    }
  }

  .messages-dropdown .main-header-dropdown {
    width: 20rem;
  }

  .notifications-dropdown .main-header-dropdown {
    width: 20rem;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
}

[dir="rtl"] {
  .sidemenu-toggle {
    .open-toggle {
      transform: rotate(180deg);
    }
  }
}

/* End::main-header-container */

/* Start::Header-dropdown */
.header-product-item {
  padding-inline-start: 0;
  display: flex;

  li {
    position: relative;
    list-style-type: none;
    font-size: 0.75rem;
    font-weight: 400;
    color: $text-muted;
    padding-inline-end: 0.938rem;
  }

  li:nth-child(2),
  li:nth-child(3),
  li:nth-child(4) {
    &:before {
      position: absolute;
      content: "";
      inset-inline-start: -0.438rem;
      width: 1px;
      inset-block-start: 0.25rem;
      height: 0.625rem;
      background-color: $text-muted;
      opacity: 0.1;
    }
  }
}

.header-cart-remove {

  .ti {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    margin-inline-start: 0.75rem;
    color: $danger;
  }

  &:hover {
    .ti {
      background-color: rgba(var(--danger-rgb), 0.1);
    }
  }
}

/* End::Header-dropdown */

/* Start::header-search */
#searchModal {
  .form-control {
    position: relative;
  }

  .input-group {
    border: 2px solid $primary;
    border-radius: 0.25rem;

    i {
      color: $text-muted;
    }
  }
}

.search-tags {
  font-size: 0.75rem;
  color: $default-text-color;
  border: 1px solid $default-border;
  border-radius: 0.25rem;
  background-color: $light;
  padding: 0.125rem 0.55rem;
  line-height: 1.2rem;
  display: inline-flex;
  align-items: center;
  cursor: default;
  font-weight: 400;
  margin: 0.25rem 0;

  .tag-addon:last-child {
    border-radius: $default-radius;
  }

  a.tag-addon {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding: 0 0.5rem;
    color: inherit;
    text-decoration: none;
    background: $light;
    margin-inline-end: -0.4rem;
    margin-inline-start: 0.5rem;
    text-align: center;
    min-width: 1.5rem;

    i {
      vertical-align: middle;
      margin: 0 -0.25rem;
      line-height: 1.5px;
      font-size: 0.813rem;
    }
  }

  i {
    font-size: 0.813rem;
  }
}

/* End::header-search */

/* Start::header-country-selector */
.country-selector {
  .header-link .flag-icon1 {
    width: 1.1875rem;
    height: 1.1875rem;
  }

  .dropdown-menu {
    img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }
  }
}

/* End::header-country-selector */

/* Start:header dropdowns scroll */
#header-shortcut-scroll,
#header-notification-scroll,
#header-cart-items-scroll {
  max-height: 17rem;
}

/* End:header dropdowns scroll */

/* Start::header badge pulse */
.pulse {
  display: block;
  cursor: pointer;
  animation: pulse-secondary 2s infinite;

  &.pulse-secondary {
    box-shadow: 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }
}

@-webkit-keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
  }
}

@keyframes pulse-secondary {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0);
    box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
  }
}

/* End::header badge pulse */

/* Start::Header theme-mode icon style */
[data-theme-mode="light"] {
  .layout-setting .dark-layout {
    display: none;
  }

  .layout-setting .light-layout {
    display: block;
  }
}

.layout-setting .dark-layout {
  display: none;
}

.layout-setting .light-layout {
  display: block;
}

[data-theme-mode="dark"] {
  .layout-setting .light-layout {
    display: none;
  }

  .layout-setting .dark-layout {
    display: block;
  }
}

/* End::Header theme-mode icon style */

/* Start::Header fullscreen responsive */
@media (max-width:767.98px) {
  .header-element.header-fullscreen {
    display: none;
  }
}

/* End::Header fullscreen responsive */

/* Start::Responsive header dropdowns */
@media (max-width:575.98px) {
  .app-header {
    .dropdown-menu {
      width: 100%;
    }
  }
}

/* End::Responsive header dropdowns */

/* Start::toggle */
.animated-arrow.hor-toggle {
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  margin: 0;
}

.animated-arrow {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  z-index: 102;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  padding: .3125rem;
  margin-block-start: .375rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0;
}

.animated-arrow.hor-toggle span {
  vertical-align: middle;
}

.animated-arrow span {
  cursor: pointer;
  height: .125rem;
  width: 0.75rem;
  background: $header-prime-color;
  position: absolute;
  display: block;
  content: "";
  transition: all 0.05s ease-in-out;
}

.animated-arrow span:before,
.animated-arrow span:after {
  transition: all 0.05s ease-in-out;
}

.animated-arrow span:before {
  inset-block-start: -0.375rem;
  width: 1.4375rem;
}

.animated-arrow span:after {
  bottom: -0.375rem;
  width: 15px;
}

.animated-arrow span:before,
.animated-arrow span:after {
  cursor: pointer;
  height: .125rem;
  width: 1.0625rem;
  background: $header-prime-color;
  position: absolute;
  display: block;
  content: "";
}

[data-toggled="icon-overlay-close"],[data-toggled="close-menu-close"],[data-toggled="icon-text-close"],[data-toggled="detached-close"],[data-toggled="menu-click-closed"],[data-toggled="menu-hover-closed"],[data-toggled="icon-click-closed"],[data-toggled="icon-hover-closed"] {
  .animated-arrow span {
    background-color: transparent;
    background: none !important;
  }

  .animated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0px;
  }

  .animated-arrow span:before {
    inset-block-start: -1px;
  }

  .animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .animated-arrow span:after {
    width: 1.0625rem;
    inset-block-start: -1px;
  }
}

/* End::toggle */

/* Start::header notification dropdown */
.header-notification-text {
  max-width: 14.5rem;
}
/* Start::header notification dropdown */

/* Start::header Search */
.main-header-center {
  flex: 1;
  position: relative;
  margin-inline-start: 0.875rem!important;
  @media (min-width: 1200px) {
    .form-control {
        width: 350px;
    }
  }
  @media (min-width: 992px) {
    .form-control {
        height: 40px;
        border-radius: 20px;
        background: $default-body-color;
        border: 1px solid $default-border;
        padding: 1rem 1.15rem;
    }
    .btn {
      position: absolute;
      inset-block-start: 0;
      inset-inline-end: 5px;
      background-color: transparent;
      height: 40px;
      color: #b4bdce;
      transition: none;
      font-size: 16px;
      padding-inline-end: 13px;
  }
  }
}
/* End::header Search */
/* Start::Header dropdown content */

  .menu-header-content {
    padding: 1.2rem;
    border-start-start-radius: 0.375rem;
    border-start-end-radius: 0.375rem;
  }

  .main-header-message {
    .name {
      font-size: 0.9rem;
    }
    .desc {
      color: #535384;
    }
    .time {
      font-size: 11px;
      color: #8d8db3;
      font-weight: 400;
    }
  }

  .notification-label {
    font-size: 13.5px;
  }
  .notification-subtext {
    color: #535384;
  }
/* End::Header dropdown content */

/* End::Header dropdown content */

.main-header-message .dropdown-footer, .main-header-notification .dropdown-footer {
  border-block-start: 1px solid #dce1ef;
  padding: 10px;
  background: #f6f6fb;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.1);
}
/* End::Header dropdown content */

/* Start::Header-sidebar content */
#header-sidebar {
  .offcanvas-header {
    background-color: $custom-bg-color;
  }
  .nav.panel-tabs {
    padding: 20px;
  }
  .tabs-menu ul li {
    width: 100%;
    display: block;
     .active {
        background: $primary !important;
        color: #fff !important;
        border: 1px solid $primary !important;
    }
    a {
      padding: 8px 16px;
      font-size: 14px;
      display: block;
      margin-block-end: 10px;
      border-radius: 3px;
      border: 1px solid $default-border;
      font-weight: 400;
      background: $default-body-color;
      color: #000 ;
    }
    &:last-child a {
      margin-block-end: 0;
    }
  }
  .card {
    border-radius: 0;
  }
  &.offcanvas {
    width: 300px;
  }
  .list-group-item {
    padding: 0.75rem 1rem;
  }
  .nav.tab-style-1 {
    background-color: transparent;
    padding: 1rem;
    margin-block-end: 0;
    .nav-link {
      padding: 10px 16px;
      font-size: 14px;
      font-weight: 400;
      background-color: $custom-bg-color;
      border-radius: 4px;
      &.active {
        background-color: $primary;
        box-shadow: none;
      }
    }
    .nav-item {
      margin-block-end: .5rem;
      margin-inline-end: 0rem;
    }
  }
  .wrapper {
    margin: 0;
    padding: 0;
  }
}
.main-profile-menu .dropdown-menu {
  width: 230px;
}
/* End::Header-sidebar content */

/* Start::Header-dropdown content */
@media (min-width: 576px) {
  .messages-dropdown, .notifications-dropdown, .headerProfile-dropdown {
    position: relative;
    .main-header-dropdown {
      &:after {
        content: "";
        position: absolute;
        inset-block-start: -8.5px;
        inset-inline-end: 14px;
        border-block-end: 9px solid $primary;
        border-inline-start: 9px solid transparent;
        border-inline-end: 9px solid transparent;
      }
    }
  }
}
.main-header-center  {
.form-control {
  &::-webkit-search-cancel-button {
    display: none;
  }
}


.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active , .btn{
  border: 0;
}
}
  
/* End::Header-dropdown content */

[data-theme-mode="dark"] {
  .main-header-message .desc, .notification-subtext {
    color: rgba(255, 255, 255, 0.65);
  }
  .main-header-message .dropdown-footer, .main-header-notification .dropdown-footer {
    border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.03);
    box-shadow: -8px 12px 18px 0 rgba(0,0,0,.1);
  }
}
/* End:: header */