/* Start:: chat */
.main-chat-list {
    height: calc(100% - 68px);
    position: relative;
    overflow: auto;
}
.main-chat-list {
    max-height: 100%;
    position: relative;
}

@media (min-width: 992px) {
    .main-chat-list .media {
        padding: 12px 15px;
    }

}
.main-chat-list .media {
    padding: 12px 20px;
    border-inline-start: 0;
    border-inline-end: 0;
    border-block-end: 1px solid $default-border;
    position: relative;
}
.main-chat-list .media .main-img-user {
    flex-shrink: 0;
}

.main-chart-wrapper .chat-users-tab {
    max-height: calc(100vh - 17.5rem);
}

.main-chat-list .media-contact-name {
    span:first-child {
        display: block;
        font-size: 14px;
        font-weight: 500;
    }
    span:last-child {
        display: block;
        font-size: 11px;
        color: $gray-5;
    }
}
.main-chat-list .media-body p {
    font-size: 12px;
    margin-block-end: 0;
    color: $gray-6;
    line-height: 1.35;
}
.main-chat-list .media.new .media-contact-name span:first-child {
    font-weight: 700;
}

.main-chat-list .media-contact-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block-end: 2px;
}
.main-chat-list {
    .main-img-user {
        width: 42px;
        height: 42px;
    }
    .media-body {
        margin-inline-start: 10px;
    }
}

.main-img-user span {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: -2px;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: 500;
    color: $white;
    background-color: $danger;
    box-shadow: 0 0 0 2px $white;
}
.main-chat-contacts-wrapper .main-img-user::after, .main-chat-list .main-img-user::after, .main-chat-header .main-img-user::after, .main-chat-body .main-img-user::after {
    inset-inline-end: 1px;
    bottom: 3px;
}

.chat-groups-tab {
    max-height: calc(100vh - 17.5rem);
}
.chat-calls-tab {
    max-height: calc(100vh - 17.5rem);
}
.chat-content {
    max-height: calc(100vh - 22rem);
}
.chat-user-details {
    height: calc(100vh - 14.1rem);
}

.main-chat-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 12px;
    border-block-end: 1px solid $default-border;
    .nav .nav-link {
        padding: 15px 12px;
    }

}
.main-img-user img {
    object-fit: cover;
    border-radius: 100%;
}
.main-chat-header .nav {
    margin-inline-start: auto;
    align-items: center;
}

@media (min-width: 576px) {
    .main-content-body-chat .main-chat-header .nav-link:first-child {
        display: none;
    }
}
.main-chat-header .nav-link:first-child {
    display: block;
}
.main-chat-time {
    span {
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

.main-chat-body {
    .media {
        align-items: start;
    }
    .content-inner {
        padding: 20px;
    }
    .media.flex-row-reverse .media-body {
        margin-inline-start: 0;
        margin-inline-end: 20px;
        align-items: flex-end;
    }
    .media-body {
        margin-inline-start: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .media-body>div:last-child {
        font-size: 11px;
        color: $text-muted;
        display: flex;
        align-items: center;
    }
}
@media (min-width: 576px) {
    .main-chat-body .media.flex-row-reverse .media-body {
        margin-inline-start: 55px;
    }
}
.main-chat-time {
    display: block;
    position: relative;
    text-align: center;
    margin: 20px 0;
    &:first-of-type {
        margin-block-start: 0;
    }
    span {
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}
.main-msg-wrapper {
    padding: 10px 15px;
    background-color: $default-body-color;
    font-size: 13px;
    margin-block-end: 5px;
    border-radius: 10px;
    display: inline-block;
    &.right:first-child {
        border-start-start-radius: 10px;
        border-start-end-radius: 0;
        border-end-start-radius: 10px;
        border-end-end-radius: 10px;
    }
    &.left:first-child {
        border-start-start-radius: 0;
        border-start-end-radius: 10px;
        border-end-start-radius: 10px;
        border-end-end-radius: 10px;
    }
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: $primary;
    color: $white;
}
.main-chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-block-start: 1px solid $default-border;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    .nav-link {
        padding: 0;
        color: $icons-color;
        font-size: 16px;
        display: none;
    }
    .form-control {
        flex: 1;
        margin: 0 10px;
        padding-inline-start: 0;
        padding-inline-end: 0;
        border-width: 0;
        background-color: transparent;
    }
}
@media (min-width: 992px) {
    .main-chat-footer {
        position: relative;
        bottom: auto;
        inset-inline-start: auto;
        inset-inline-end: auto;
    }
}
@media (min-width: 768px) {
    .main-chat-footer .form-control {
        margin-inline-end: 20px !important;
    }
}
.main-msg-send {
    font-size: 20px;
    color: $primary;
}
/* End:: chat */